"use client";

import { usePathname } from "next/navigation";
import * as React from "react";

import { Link } from "~/components/link";
import { LogoNoText } from "~/components/logo-no-text";
import { MobileNav } from "~/components/mobile-nav";
import { Translation } from "~/components/translations/component";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/components/ui/context-menu";
import { cn } from "~/lib/utils";

type NavItem = {
  title: string;
  href: string;
  disabled?: boolean;
};

interface MainNavProps {
  items?: NavItem[];
}

export function MainNav({ items }: MainNavProps) {
  const pathname = usePathname();

  return (
    <div className="flex gap-4 md:gap-10">
      <MobileNav
        mainNav={(items ?? []).map((item) => ({
          title: item.title,
          href: item.href,
        }))}
        sidebarNav={[]}
      />
      <ContextMenu>
        <ContextMenuTrigger>
          <Link href="/" className="flex items-center space-x-2">
            <LogoNoText className="-ml-4 mr-0 h-10 w-10" />
            <span className="font-logo text-lg">Nextgen</span>
          </Link>
        </ContextMenuTrigger>
        <ContextMenuContent className="w-48">
          <ContextMenuItem>
            <Link href="/logo">
              <Translation id="main.download-logos">
                Ladda hem logotyp
              </Translation>
            </Link>
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
      {items?.length ? (
        <nav className="hidden gap-6 md:flex">
          {items.map((item, index) => {
            return (
              <Link
                key={index}
                href={item.disabled ? "#" : item.href}
                className={cn(
                  "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                  item.href === pathname
                    ? "border-primary"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:border-gray-700 dark:hover:text-gray-300",
                  item.disabled && "cursor-not-allowed opacity-80",
                )}
              >
                {item.title}
              </Link>
            );
          })}
        </nav>
      ) : null}
    </div>
  );
}
